<template>
    <template v-if="isMounted">
        <slot/>
    </template>
    <template v-else>
        <slot name="fallback"/>
    </template>
</template>

<script setup>
import {ref, onMounted, nextTick} from 'vue'

const isMounted = ref(false)
onMounted(() => {
    if(typeof window !== 'undefined') {
        nextTick(() => {
            isMounted.value = true
        })
    }
})
</script>
